<template>
  <div class="d-flex flex-row justify-content-between">
    <div class="min-h-screen flex overflow-x-scroll py-12 kanban flex-column">
      <div class="columns-container">
        <TaskColumn
          v-for="(column, index) in columns"
          :key="column.id"
          :animated-task-id="animatedTaskId"
          :color="colors[index]"
          :column="column"
          :selected-task-ids="selectedTaskIds"
          :archive-permission="archivePermission"
          :update-permission="updatePermission"
          @change="$emit('handle-drag-and-drop', $event)"
          @open-focus-task="$emit('open-focus-task', $event)"
          @handle-show-bulk-actions="$emit('handle-show-bulk-actions')"
          @update-selected-tasks="$emit('update-selected-tasks', $event)"
          @add-upvote="$emit('add-upvote', $event)"
          @remove-upvote="$emit('remove-upvote', $event)"
          @handle-drag-and-drop="$emit('handle-drag-and-drop', $event)"
          @fetch-next-page="$emit('fetch-next-page', $event)"
        />
      </div>

      <div v-if="isFilteredAndEmpty" class="empty-filtered">
        <div class="empty-msg">
          {{ $t('task-view.empty-state.empty-msg') }}
        </div>
        <img src="@/assets/empty-task.svg" alt="empty-task" class="empty-img" />
        <div class="empty-explanation">
          {{ $t('task-view.empty-state.empty-explaination') }}
        </div>
        <el-button type="primary" @click="$emit('empty-filters')"
          >{{ $t('task-view.empty-state.empty-button') }}
        </el-button>
      </div>
    </div>
    <task-focus-modal
      v-if="modals.focus"
      :loader="loadingFocusTask"
      :display="modals.focus"
      :task="focusTask"
      :archive-permission="archivePermission"
      :comment-permission="commentPermission"
      :update-permission="updatePermission"
      @close="$emit('close-focus-modal')"
      @handle-update-assignee="$emit('handle-update-assignee', $event)"
      @handle-update-status="$emit('handle-update-status', $event)"
      @handle-is-archive="$emit('handle-is-archive', $event)"
      @add-upvote="$emit('add-upvote', $event)"
      @remove-upvote="$emit('remove-upvote', $event)"
      @handle-new-comment="$emit('handle-new-comment', $event)"
      @delete-comment="$emit('delete-comment', $event)"
      @update-rating="$emit('update-rating', $event)"
      @delete-rating="$emit('delete-rating', $event)"
    ></task-focus-modal>
    <task-assignee-modal
      v-if="modals.assignee"
      :display="modals.assignee"
      @close="$emit('close-assignee-modal')"
      @handle-update-assignee="$emit('handle-update-assignee', $event)"
    ></task-assignee-modal>
  </div>
</template>

<script>
import TaskAssigneeModal from '../commons/TaskAssigneeModal.vue';
import TaskFocusModal from '../commons/TaskFocusModal.vue';
import TaskColumn from './TaskColumn.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'task-board',
  components: {
    TaskColumn,
    TaskAssigneeModal,
    TaskFocusModal,
  },
  props: {
    columns: Array,
    modals: {
      type: Object,
      default: () => {},
    },
    focusTask: {
      type: Object,
      default: () => {},
    },
    showBulkActions: {
      type: Boolean,
      default: false,
    },
    selectedTaskIds: {
      type: Array,
      default: () => [],
    },
    animatedTaskId: {
      type: String,
      default: '',
    },
    archivePermission: {
      type: Boolean,
      default: false,
    },
    commentPermission: {
      type: Boolean,
      default: false,
    },
    updatePermission: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      companyTaskStatus: null,
      colors: ['grey', 'orange', 'blue', 'green', 'purple'],
    };
  },
  computed: {
    isFilteredAndEmpty() {
      return (
        !this.isLoading &&
        this.tasksCount <= 0 &&
        Object.keys(this.$route.query).length
      );
    },
    ...mapGetters('taskModule', ['loadingFocusTask', 'tasksCount']),
    ...mapGetters('knowledgeModule', ['mappedKnowledges']),
  },
};
</script>

<style lang="scss" scoped>
.kanban {
  width: 100%;
}

.empty-filtered {
  position: absolute;
  top: calc(50% - 100px);
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.empty-msg {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  margin-bottom: 16px;
}

.empty-explanation {
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: $grey-7-mayday;
  margin-bottom: 16px;
  max-width: 313px;
}

.columns-container {
  width: 100%;
  height: calc(100vh - 100px);
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  padding: 16px;

  > :not(:last-child) {
    margin-right: 16px;
  }
}
</style>
