<template>
  <div v-if="display">
    <div class="d-flex flex-row pl-2">
      <SearchInFocusCase
        :focus-case-id="focusCaseId"
        v-if="displaySearchInFocusCase"
        @activate-search-focus-case="
          $emit('activate-search-focus-case', $event)
        "
      ></SearchInFocusCase>
      <QuickFilters
        :chosen-dates="chosenDates"
        :chosen-user="chosenUser"
        :has-verification-policy="hasVerificationPolicy"
        :chosen-verification-policy-due-date="chosenVerificationPolicyDueDate"
        @set-dates="handleDateFilters"
        @set-user="handleUserFilter"
        @set-has-verification-policy="handleHasVerificationPolicyFilter"
        @set-verification-due-date="handleVerificationPolicyDueDateFilter"
      ></QuickFilters>
    </div>

    <div class="tag-wrapper" v-if="showFilters">
      <tags-wrapper v-if="showEntities" :tags="entities">
        <template v-slot:tag="{ tag }">
          <Tag
            :tag="tag"
            :selected="isSelected(tag.id)"
            @focus="handleEntity"
          />
        </template>
      </tags-wrapper>

      <tags-wrapper
        v-if="keywords.length && !isInAsk"
        class="mt-2"
        :label="$t('knowledge.search-admin.keywords')"
        :size="24"
        :tags="keywords"
      >
        <template v-slot:tag="{ tag }">
          <div class="keyword" @click.stop="$emit('update-keyword', tag)">
            {{ tag }}
          </div>
        </template>
      </tags-wrapper>

      <tags-wrapper
        v-if="
          showProductsFilters && univers.length && !families.length && !isInAsk
        "
        class="mt-2"
        :label="
          showMoreFilters
            ? 'Base de connaissance'
            : $t('knowledge.search-admin.product-univers')
        "
        :tags="univers"
        :selectedTags="selectedTags"
      >
        <template v-slot:tag="{ tag }">
          <Tag
            :tag="tag"
            :selected="isSelected(tag.id)"
            @focus="handleUnivers"
          />
        </template>
      </tags-wrapper>

      <tags-wrapper
        v-if="showProductsFilters && families.length && !isInAsk"
        class="mt-2"
        :label="$t('knowledge.search-admin.family')"
        :tags="families"
        :selectedTags="selectedTags"
      >
        <template v-slot:tag="{ tag }">
          <Tag
            :tag="tag"
            :selected="isSelected(tag.id)"
            @focus="handleFamily"
          />
        </template>
      </tags-wrapper>

      <tags-wrapper
        v-if="showProductsFilters && brands.length && !isInAsk"
        class="mt-2"
        :label="$t('knowledge.search-admin.brands')"
        :tags="brands"
        :selectedTags="selectedTags"
      >
        <template v-slot:tag="{ tag }">
          <Tag :tag="tag" :selected="isSelected(tag.id)" @focus="handleBrand" />
        </template>
      </tags-wrapper>

      <div v-if="searchableLanguages.length && !isInAsk">
        <div class="label font-bold mb-1">
          {{ $t('knowledge.search-admin.advanced') }}
        </div>
        <TranslationDropdown
          rightDirectionDropdown
          :languages="searchableLanguages"
          :current-language="searchLanguage"
          @change-language="$emit('change-language', $event)"
        />
      </div>

      <tags-wrapper
        v-if="showTypes"
        class="mt-2"
        :label="$t('knowledge.search-admin.type')"
        :tags="types"
        :selectedTags="selectedTags"
      >
        <template v-slot:tag="{ tag }">
          <Tag :tag="tag" :selected="isSelected(tag.id)" @focus="handleType" />
        </template>
      </tags-wrapper>

      <tags-wrapper
        v-if="labels.length"
        class="mt-2"
        :label="$t('knowledge.search-admin.labels')"
        :tags="labels"
        :selectedTags="selectedTags"
      >
        <template v-slot:tag="{ tag }">
          <Tag :tag="tag" :selected="isSelected(tag.id)" @focus="handleLabel" />
        </template>
      </tags-wrapper>

      <tags-wrapper
        v-if="showMoreFilters && !isAgent && !isInAsk"
        class="mt-2"
        :label="$t('knowledge.search-admin.content-status')"
        :tags="status"
        :selectedTags="selectedTags"
      >
        <template v-slot:tag="{ tag }">
          <Tag
            :tag="tag"
            :selected="isSelected(tag.id)"
            @focus="handleStatus"
          />
        </template>
      </tags-wrapper>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TagsWrapper from './TagsWrapper';
import Tag from './Tag';

import { getFilterByKey } from './ResolveSuggestFilter';

import SearchInFocusCase from './SearchInFocusCase.vue';
import TranslationDropdown from '../TranslationDropdown';

import QuickFilters from './QuickFilters.vue';

export default {
  components: {
    TagsWrapper,
    Tag,
    SearchInFocusCase,
    TranslationDropdown,
    QuickFilters,
  },
  props: {
    isInAsk: {
      type: Boolean,
      default: false,
    },
    isAgent: {
      type: Boolean,
      default: false,
    },
    selectedTags: {
      type: Array,
      default: () => [],
    },
    keywords: {
      type: Array,
      default: () => [],
    },
    display: {
      type: Boolean,
      default: true,
    },
    showMoreFilters: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    searchInProgress: {
      type: Boolean,
      default: true,
    },
    searchableLanguages: {
      type: Array,
      default: () => [],
    },
    searchLanguage: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showProductsFilters: true,
      entities: [],
      chosenEntity: [],
      types: [],
      chosenTypes: [],
      status: [],
      chosenStatus: [],
      labels: [],
      chosenLabels: [],
      univers: [],
      chosenUnivers: [],
      families: [],
      chosenFamilies: [],
      brands: [],
      chosenBrands: [],
      chosenCodics: [],
      chosenDates: [],
      chosenUser: [],
      hasVerificationPolicy: [],
      chosenVerificationPolicyDueDate: [],
    };
  },
  watch: {
    async showMoreFilters(show) {
      if (show && !this.univers.length) await this.getHierarchies();
      this.showProductsFilters = show;
    },
    selectedTags(tags) {
      this.chosenEntity = tags.filter((t) => t.key === 'entity');
      this.chosenTypes = tags.filter((t) => t.key === 'type');
      this.chosenStatus = tags.filter(
        (t) =>
          t.key === 'published' ||
          t.key === 'isOutdated' ||
          t.key === 'contribution',
      );
      this.chosenUnivers = tags.filter((t) => t.key === 'knowledge');
      this.chosenFamilies = tags.filter((t) => t.key === 'family');
      this.chosenBrands = tags.filter((t) => t.key === 'brand');
      this.chosenLabels = tags.filter((t) => t.key === 'categories');
      this.chosenCodics = tags.filter((t) => t.key === 'codic');
      this.chosenDates = tags.filter(
        (t) => t.key === 'createdAt' || t.key === 'updatedAt',
      );
      this.chosenUser = tags.filter(
        (t) => t.key === 'authorId' || t.key === 'contentUpdatedBy',
      );
      this.hasVerificationPolicy = tags.filter(
        (t) => t.key === 'verificationPolicy.hasVerificationPolicy',
      );
      this.chosenVerificationPolicyDueDate = tags.filter(
        (t) => t.key === 'verificationPolicy.verificationDueDate',
      );
      if (!this.chosenEntity.length && this.isParametric) {
        this.univers = [];
        this.families = [];
        this.brands = [];
        this.labels = [];
      } else if (!this.chosenUnivers.length) {
        this.families = [];
        this.brands = [];
      } else if (!this.chosenFamilies.length) {
        this.brands = [];
      }
    },
  },
  computed: {
    showEntities() {
      return !this.chosenEntity.length && this.isParametric && !this.isInAsk;
    },
    showTypes() {
      return (
        ((this.chosenEntity.length &&
          this.chosenEntity[0].value == 'attached-contents') ||
          !this.isParametric) &&
        !this.chosenTypes.length &&
        !this.isInAsk
      );
    },
    searchEntity() {
      return this.chosenEntity[0] ? this.chosenEntity[0].value : '';
    },
    newSelectedTags() {
      return [
        ...this.chosenEntity,
        ...this.chosenTypes,
        ...this.chosenLabels,
        ...this.chosenStatus,
        ...this.chosenUnivers,
        ...this.chosenFamilies,
        ...this.chosenBrands,
        ...this.chosenCodics,
        ...this.chosenDates,
        ...this.chosenUser,
        ...this.hasVerificationPolicy,
        ...this.chosenVerificationPolicyDueDate,
      ];
    },
    focusCaseId() {
      return this.$route.params.caseId;
    },
    displaySearchInFocusCase() {
      return (
        this.focusCaseId &&
        !this.searchInProgress &&
        this.searchFocusLevel !== 'case' &&
        this.companyAllowFilterFocusCase &&
        !this.isInAsk
      );
    },
    ...mapGetters([
      'isParametric',
      'hasContributions',
      'hasCompanyPreferenceWithValue',
      'companyAllowFilterFocusCase',
    ]),
    ...mapGetters('webKnowledgeModule', ['searchFocusLevel']),
    ...mapGetters('knowledgeModule', ['knowledges']),
  },
  methods: {
    isSelected(tagId) {
      return this.selectedTags.map((t) => t.id).includes(tagId);
    },
    async handleEntity(entity) {
      await this.loadEntityNextLevel(entity);
      this.updateSelectedTags();
    },
    async handleUnivers(univers) {
      const index = this.chosenUnivers.findIndex(
        (u) => u.value === univers.value,
      );
      if (index === -1) await this.loadUniversNextLevel(univers);
      else this.chosenUnivers = [];

      this.updateSelectedTags();
    },
    handleType(type) {
      const index = this.chosenTypes.findIndex((t) => t.value === type.value);
      index === -1
        ? this.chosenTypes.push(type)
        : this.chosenTypes.splice(index, 1);

      this.updateSelectedTags();
    },
    async handleLabel(label) {
      const index = this.chosenLabels.findIndex((l) => l.value === label.value);

      index === -1
        ? this.chosenLabels.push(label)
        : this.chosenLabels.splice(index, 1);

      this.updateSelectedTags();
    },
    handleStatus(status) {
      const index = this.chosenStatus.findIndex(
        (s) => s.value === status.value && s.key === status.key,
      );
      index === -1
        ? this.chosenStatus.push(status)
        : this.chosenStatus.splice(index, 1);

      this.updateSelectedTags();
    },
    async handleFamily(family) {
      const index = this.chosenFamilies.findIndex(
        (f) => f.value === family.value,
      );

      if (index === -1) {
        family.key = 'family';
        this.chosenFamilies.push(family);
      } else {
        this.chosenFamilies.splice(index, 1);
        this.chosenBrands = [];
      }

      await this.loadFamilyNextLevel(this.chosenFamilies);
      this.updateSelectedTags();
    },
    async handleBrand(brand) {
      const index = this.chosenBrands.findIndex((b) => b.value === brand.value);

      if (index === -1) {
        brand.key = 'brand';
        this.chosenBrands.push(brand);
      } else {
        this.chosenBrands.splice(index, 1);
      }

      this.updateSelectedTags();
    },
    handleDateFilters(dateFilter) {
      if (!dateFilter.key) this.chosenDates = [];
      else
        this.chosenDates = [
          {
            key: dateFilter.key,
            value: dateFilter.value.value(),
            label: this.$tc(
              `knowledge.search-admin.quick-filters.dates.${dateFilter.key}`,
              dateFilter.value.key,
            ),
            trad: false,
            noArray: true,
          },
        ];
      this.updateSelectedTags();
    },
    handleUserFilter(userFilter) {
      if (!userFilter.key) this.chosenUser = [];
      else {
        this.chosenUser = [
          {
            key: userFilter.key,
            value: userFilter.value.value,
            label: this.$t(
              `knowledge.search-admin.quick-filters.user.${userFilter.key}`,
              { user: userFilter.value.key },
            ),
            trad: false,
            noArray: true,
          },
        ];
      }
      this.updateSelectedTags();
    },
    handleHasVerificationPolicyFilter(hasVerificationPolicyFilter) {
      if (hasVerificationPolicyFilter === null) this.hasVerificationPolicy = [];
      else {
        this.hasVerificationPolicy = [
          {
            key: 'verificationPolicy.hasVerificationPolicy',
            value: hasVerificationPolicyFilter,
            label: this.$t(
              `knowledge.search-admin.quick-filters.verification-policy.has.${hasVerificationPolicyFilter}`,
            ),
            trad: false,
            noArray: true,
          },
        ];
      }
      this.updateSelectedTags();
    },
    handleVerificationPolicyDueDateFilter(verificationPolicyDueDateFilter) {
      if (verificationPolicyDueDateFilter === null)
        this.chosenVerificationPolicyDueDate = [];
      else {
        this.chosenVerificationPolicyDueDate = [
          {
            key: 'verificationPolicy.verificationDueDate',
            value: verificationPolicyDueDateFilter.value.value(),
            label: this.$tc(
              `knowledge.search-admin.quick-filters.dates.verificationDueDate`,
              verificationPolicyDueDateFilter.value.key,
            ),
            trad: false,
            noArray: true,
          },
        ];
      }
      this.updateSelectedTags();
    },
    async loadEntityNextLevel(entity) {
      this.chosenEntity = [entity];
      if (entity.value === 'new-products') {
        this.showProductsFilters = true;
        await this.getHierarchies();
      } else {
        await this.loadLabels();
      }
      return;
    },
    async loadUniversNextLevel(univers) {
      this.chosenUnivers = [univers];
      let families = await this.$services.hierarchies.getAggregatedOptions(
        this.chosenUnivers[0].value,
      );
      families = this.sortArrayObjectByKey(families, 'label');
      this.families = families.map((f) => {
        f.key = 'family';
        return f;
      });
      return;
    },
    async loadFamilyNextLevel(families) {
      const familiesValues = families.map((family) => {
        return family.value;
      });
      let brands = await this.$services.hierarchies.getAggregatedOptions(
        familiesValues,
      );
      brands = this.sortArrayObjectByKey(brands, 'label');
      this.brands = brands.map((b) => {
        b.key = 'brand';
        return b;
      });
      return;
    },
    async loadLabels() {
      let labels =
        await this.$services.contentParameterLabels.getContentParameterLabels();
      this.labels = labels.map((label) => {
        label.key = 'categories';
        label.label = label.labelName;
        label.value = label.id;
        return label;
      });
      this.labels.unshift({
        id: 'none',
        key: 'categories',
        label: this.$t('filters.labels-filter.no-label'),
        value: null,
        icon: ['fad', 'tag'],
      });
    },
    updateSelectedTags() {
      this.$emit('update-selected-tags', this.newSelectedTags);
    },
    enrichTags() {
      const filters = [
        ...this.entities,
        ...this.types,
        ...this.status,
        ...this.labels,
        ...this.univers,
        ...this.families,
        ...this.brands,
        ...this.chosenDates,
        ...this.chosenUser,
        ...this.hasVerificationPolicy,
        ...this.chosenVerificationPolicyDueDate,
      ];
      return this.newSelectedTags.reduce((acc, tag) => {
        let newTag = filters.find(
          (f) => f.key === tag.key && f.value === tag.value,
        );
        if (newTag) acc.push(newTag);
        else if (tag.key === 'codic') acc.push(tag);
        return acc;
      }, []);
    },
    async initTags(preTags) {
      this.chosenEntity = [];
      this.chosenTypes = [];
      this.chosenStatus = [];
      this.labels = [];
      this.chosenLabels = [];
      this.univers = [];
      this.chosenUnivers = [];
      this.families = [];
      this.chosenFamilies = [];
      this.brands = [];
      this.chosenBrands = [];
      this.chosenCodics = [];
      const entity = preTags.find((t) => t.key === 'entity');
      if (entity) {
        await this.loadEntityNextLevel(entity);
      }
      const univers = preTags.find((t) => t.key === 'knowledge');
      if (univers) {
        if (!this.univers.length) {
          await this.getHierarchies();
        }
        await this.loadUniversNextLevel(univers);
      }
      const families = preTags.filter((t) => t.key === 'family');
      if (families.length) {
        this.chosenFamilies = families;
        await this.loadFamilyNextLevel(families);
      }
      const labels = preTags.filter((t) => t.key === 'categories');
      if (labels.length) {
        this.chosenLabels = labels;
        await this.loadLabels();
      }
      this.chosenEntity = preTags.filter((t) => t.key === 'entity');
      this.chosenTypes = preTags.filter((t) => t.key === 'type');
      this.chosenStatus = preTags.filter(
        (t) => t.key === 'published' || t.key === 'isOutdated',
      );
      this.chosenBrands = preTags.filter((t) => t.key === 'brand');
      this.chosenCodics = preTags.filter((t) => t.key === 'codic');
      this.chosenDates = preTags.filter(
        (t) => t.key === 'createdAt' || t.key === 'updatedAt',
      );
      const userFilter = preTags.filter(
        (t) => t.key === 'authorId' || t.key === 'contentUpdatedBy',
      );
      if (userFilter.length && userFilter[0] && userFilter[0].value) {
        const user = await this.getUserById({ id: userFilter[0].value });
        userFilter[0].label = this.$t(
          `knowledge.search-admin.quick-filters.user.${userFilter[0].key}`,
          { user: user.username },
        );
        this.chosenUser = userFilter;
      }
      this.hasVerificationPolicy = preTags.filter(
        (t) => t.key === 'verificationPolicy.hasVerificationPolicy',
      );
      this.chosenVerificationPolicyDueDate = preTags.filter(
        (t) => t.key === 'verificationPolicy.verificationDueDate',
      );
      return this.enrichTags();
    },
    // This inits first level of hierarchies which is always knowledge
    async getHierarchies() {
      const univers = this.knowledges;
      this.univers = this.sortArrayObjectByKey(univers, 'label');
    },
    sortArrayObjectByKey(items, key) {
      return items.sort(function (a, b) {
        if (!a || !b) return true;
        return a[key].localeCompare(b[key]);
      });
    },
    goToDocument(doc) {
      this.routeToPath({ item: doc, source: 'search' });
      this.$emit('close-dropdown');
    },
    ...mapActions('webKnowledgeModule', ['routeToPath']),
    ...mapActions('adminModule', ['getUserById']),
  },
  async mounted() {
    this.entities = getFilterByKey(['entity']);
    this.types = getFilterByKey(['type']);
    this.status = getFilterByKey(['published', 'isOutdated']);
    if (!this.isParametric) await this.loadLabels();
    if (this.hasContributions)
      this.status.push({
        id: 'contribution',
        key: 'contribution',
        value: 'true',
        label: 'Contributions externes',
        icon: ['fad', 'inbox-in'],
      });
  },
};
</script>

<style lang="scss" scoped>
.keyword {
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  border-bottom: 1px solid $grey-8-mayday;
  cursor: pointer;
}
.tag-wrapper {
  padding: 8px 16px 12px 16px;
}

.label {
  font-size: 12px;
  color: $grey-7-mayday;
}
.font-bold {
  font-weight: bold;
}
</style>
