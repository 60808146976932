<template>
  <div class="d-flex flex-column">
    <div v-for="(comment, index) in comments" :key="comment.id">
      <TaskFocusThreadItem
        :isEditing="isEditing"
        :comment="comment"
        :isLast="index === comments.length - 1"
        :disabled="disabled"
        :timeAgoReset="timeAgoReset"
        @init-delete="handleInitDelete"
        @cancel-delete="handleCancelDelete"
        @confirm-delete="handleConfirmDelete"
      ></TaskFocusThreadItem>
    </div>
  </div>
</template>

<script>
import TaskFocusThreadItem from './TaskFocusThreadItem';

export default {
  name: 'task-focus-thread',
  components: {
    TaskFocusThreadItem,
  },
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeAgoReset: false,
      commentToDelete: null,
    };
  },
  methods: {
    handleInitDelete({ id }) {
      this.commentToDelete = id;
    },
    handleCancelDelete() {
      this.commentToDelete = null;
    },
    handleConfirmDelete() {
      if (this.commentToDelete) {
        this.$emit('delete-comment', { id: this.commentToDelete });
      }
    },
  },
  watch: {
    comments() {
      this.timeAgoReset = !this.timeAgoReset;
    },
  },
};
</script>

<style lang="scss" scoped></style>
