var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('div',{class:[
      'bg-white shadow-custom p-2 task-box',
      { 'selected-task': _vm.selected, disabled: !_vm.archivePermission },
    ],on:{"click":_vm.handleCardOnClick}},[(_vm.isAnimated)?_c('div',{staticClass:"animation"},[_c('lottie-animation',{attrs:{"path":"lottie/74694-confetti.json"}})],1):_vm._e(),_c('div',{class:[_vm.isAnimated ? 'hide-if-animated' : 'not-animated']},[_c('div',{staticClass:"d-flex flex-row justify-content-between"},[_c('div',{staticClass:"d-flex justify-content-start align-items-start task-header"},[(!_vm.selected)?_c('div',{class:[
              'align-items-center justify-content-center',
              `type-icon-${_vm.currentTaskTypeConfiguration.classTag}-wrapper`,
            ]},[_c('font-awesome-icon',{class:[`type-icon-${_vm.currentTaskTypeConfiguration.classTag}`],attrs:{"icon":['fad', _vm.taskIcon]}})],1):_vm._e(),_c('div',{class:[
              'align-items-center justify-content-center',
              `checkbox-${_vm.currentTaskTypeConfiguration.classTag}-wrapper`,
            ],style:(_vm.checkboxChecked)},[_c('el-checkbox',{staticClass:"mb-0 d-flex checkbox",attrs:{"value":_vm.selected},on:{"change":_vm.handleCheckboxChange}})],1),_c('p',{staticClass:"task-title mb-0"},[_vm._v(" "+_vm._s(_vm.taskTitle)+" ")])]),_c('div',{staticClass:"expand-wrapper"},[_c('el-tooltip',{attrs:{"content":_vm.$t('task-view.expand-tooltip')}},[_c('font-awesome-icon',{staticClass:"icon-enlarge",attrs:{"icon":['fas', 'expand']},on:{"click":function($event){return _vm.$emit('open-focus-task', { taskId: _vm.task.id })}}})],1)],1)]),_c('div',{staticClass:"d-flex flex-row align-items-center task-info"},[_c('el-tooltip',{attrs:{"content":_vm.$t('task-view.date-tooltip')}},[_c('div',{staticClass:"task-date d-flex align-items-center"},[_c('font-awesome-icon',{staticClass:"icon-calendar mr-1",attrs:{"icon":['fal', 'calendar-week']}}),_c('span',{staticClass:"sm"},[_vm._v(_vm._s(_vm.task.createdAt))])],1)]),(_vm.currentTaskAttributes.linkedContent)?_c('el-tooltip',{attrs:{"content":_vm.$t('task-view.link-tooltip')}},[_c('div',{staticClass:"task-link"},[(_vm.currentTaskAttributes.linkedContent)?_c('div',{staticClass:"icon-circle rounded-circle",on:{"click":function($event){$event.stopPropagation();return _vm.handleRedirect.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"icon-link",attrs:{"icon":['fal', 'link']}})],1):_vm._e()])]):_vm._e(),(_vm.knowledge)?_c('el-tooltip',{attrs:{"content":_vm.$t('task-view.knowledge-tooltip', { label: _vm.knowledge.label }),"effect":"dark"}},[_c('div',{staticClass:"task-link"},[(_vm.task.type === 'CONTENT_FEEDBACK' || _vm.isObsolete)?_c('div',{staticClass:"icon-circle rounded-circle"},[_c('font-awesome-icon',{staticClass:"icon-link",attrs:{"icon":['fad', 'books']}})],1):_vm._e()])]):_vm._e(),(!_vm.isInfo && !_vm.isObsolete)?_c('el-tooltip',{attrs:{"content":_vm.authorHasUsername
              ? _vm.$t('task-view.author-tooltip', {
                  name: _vm.authorUsername,
                })
              : _vm.$t('task-view.author-tooltip-deleted-user')}},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('user-avatar',{staticClass:"mr-2 avatar",attrs:{"username":_vm.authorUsername,"size":"xsmall"}})],1)]):_c('el-tooltip',{attrs:{"content":_vm.$t('task-view.admin-author-tooltip')}},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('img',{staticClass:"logo-admin",attrs:{"src":require("@/assets/mayday-logo.png"),"alt":"mayday-logo-rounded"}})])])],1),_c('div',{ref:"innerHtmlContainer",staticClass:"inner-html-body d-flex flex-column p-0",class:_vm.isInnerHtmlOverflowing ? 'mb-2' : ''},[_c('div',{staticClass:"body",domProps:{"innerHTML":_vm._s(_vm.sanitize(_vm.body))}}),(_vm.isInnerHtmlOverflowing)?_c('div',{staticClass:"inner-html-overflow-cover"}):_vm._e()]),_c('div',{staticClass:"task-footer d-flex flex-row justify-content-between align-items-center"},[(_vm.currentTaskAttributes.answer)?_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',{staticClass:"icon-wrapper"},[_c('font-awesome-icon',{class:_vm.task.commentCount > 0
                  ? 'notification-active'
                  : 'notification-inactive',attrs:{"icon":['fas', 'comment']}})],1),_c('div',{class:_vm.task.commentCount > 0 ? 'count-active' : 'count-inactive'},[_vm._v(" "+_vm._s(_vm.task.commentCount)+" ")]),(_vm.currentTaskAttributes.reaction)?_c('el-tooltip',{attrs:{"disabled":!_vm.updatePermission}},[_c('template',{slot:"content"},[(_vm.taskHasUpvotes > 0)?_c('div',_vm._l((_vm.task.upvotes),function(upvoter){return _c('div',{key:upvoter.id},[_vm._v(" "+_vm._s(upvoter.username)+" ")])}),0):_c('div',[_vm._v(" "+_vm._s(_vm.$t('task-view.empty-upvote-tooltip'))+" ")])]),_c('div',{staticClass:"icon-wrapper",on:{"click":function($event){$event.stopPropagation();return _vm.handleUpvote.apply(null, arguments)}}},[_c('font-awesome-icon',{class:[
                  _vm.taskHasUpvotes
                    ? 'notification-active'
                    : 'notification-inactive',
                  'upvote',
                ],attrs:{"icon":[_vm.taskHasUpvotes ? 'fas' : 'fal', 'heart']}})],1)],2):_vm._e(),(_vm.taskHasUpvotes)?_c('div',{staticClass:"count-active"},[_vm._v(" "+_vm._s(_vm.task.upvoteCount)+" ")]):_vm._e(),(_vm.task.rating && _vm.task.rating.average)?_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',{staticClass:"icon-wrapper"},[_c('font-awesome-icon',{staticClass:"notification-inactive",attrs:{"icon":['fas', 'star']}})],1),_c('div',{staticClass:"count-inactive"},[_vm._v(_vm._s(_vm.task.rating.average)+"/5")])]):_vm._e(),(_vm.isInnerHtmlOverflowing)?_c('div',{staticClass:"px-1 text-xs underline text-blue"},[_vm._v(" "+_vm._s(_vm.$t('task-view.show-more'))+" ")]):_vm._e()],1):_vm._e(),(_vm.task.assignee)?_c('el-tooltip',{attrs:{"content":_vm.$t('task-view.assignee-tooltip', {
              name: _vm.taskAssigneeLabel,
            }),"effect":"dark"}},[(_vm.task.assignee)?_c('user-avatar',{staticClass:"avatar",attrs:{"username":_vm.taskAssigneeLabel,"size":"xsmall"}}):_vm._e()],1):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }