<template>
  <div class="bg-gray-100 rounded-lg rounded column">
    <div :class="['d-flex flex-row align-items-center column-header', color]">
      <p
        class="column-title mb-0"
        @click="displayPublicIdInURL(column.publicId)"
      >
        {{ column.name }}
      </p>
      <p class="column-content-count mb-0">
        {{ column.total }}
      </p>
    </div>
    <draggable
      :animation="200"
      :list="column.tasks"
      :disabled="!updatePermission"
      ref="task-list"
      class="draggable-task"
      ghost-class="ghost-card"
      group="tasks"
      @change="
        updatePermission &&
          $emit('handle-drag-and-drop', {
            $event,
            companyTaskStatusId: column.id,
          })
      "
      v-loading="isLoading"
    >
      <task-card
        v-for="task in column.tasks"
        :key="task.id"
        :animatedTaskId="animatedTaskId"
        :knowledge="mappedKnowledges[task.knowledgeId]"
        :selected="selectedTaskIds.includes(task.id)"
        :task="task"
        :archive-permission="archivePermission"
        :update-permission="updatePermission"
        class="mt-3 cursor-move ghost-task"
        @open-focus-task="$emit('open-focus-task', $event)"
        @handle-show-bulk-actions="$emit('handle-show-bulk-actions')"
        @update-selected-tasks="$emit('update-selected-tasks', $event)"
        @add-upvote="$emit('add-upvote', $event)"
        @remove-upvote="$emit('remove-upvote', $event)"
      ></task-card>
    </draggable>
  </div>
</template>

<script>
import TaskCard from 'views/TaskView/commons/TaskCard.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'task-column',
  components: { TaskCard },
  props: {
    column: Object,
    color: String,
    tasks: Array,
    showBulkActions: {
      type: Boolean,
      default: false,
    },
    selectedTaskIds: {
      type: Array,
      default: () => [],
    },
    animatedTaskId: {
      type: String,
      default: '',
    },
    updatePermission: {
      type: Boolean,
      default: false,
    },
    archivePermission: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('knowledgeModule', ['mappedKnowledges']),
  },
  methods: {
    displayPublicIdInURL(publicId) {
      this.$router.replace({
        query: {
          publicId,
        },
      });
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      const isBottom =
        Math.ceil(scrollTop + clientHeight) >= scrollHeight - 100;

      if (
        isBottom &&
        !this.isLoading &&
        this.column.page < this.column.pages &&
        this.column.tasks.length < this.column.total
      ) {
        this.isLoading = true;
        this.$emit('fetch-next-page', this.column.id);
      }
    },
  },
  mounted() {
    this.$refs['task-list'].$el.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.$refs['task-list'].$el.removeEventListener(
      'scroll',
      this.handleScroll,
    );
  },
  watch: {
    ['column.tasks']: {
      handler(val, oldVal) {
        if (!oldVal && val) this.isLoading = false;
        if (oldVal) {
          if (oldVal.length < val.length) this.isLoading = false;
          if (
            val.length < oldVal.length &&
            val.length <= 5 &&
            this.column.page < this.column.pages
          ) {
            this.loading = true;
            this.$emit('fetch-next-page', this.column.id);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  flex: 1;
  min-width: 240px;
  max-height: calc(100vh - 100px);
}
.column-header {
  padding-bottom: 9px;
}
.column-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-right: 10px;
}
.column-content-count {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: center;
  background-color: $grey-2-mayday;
  color: $grey-6-mayday;
  width: 20px;
  height: 15px;
  border-radius: 4px;
}
.grey {
  border-bottom: 4px solid $grey-4-mayday;
}
.orange {
  border-bottom: 4px solid $yellow-mayday;
}
.blue {
  border-bottom: 4px solid $blue-mayday;
}
.green {
  border-bottom: 4px solid $green-mayday;
}
.purple {
  border-bottom: 4px solid $red-1-mayday;
}

.ghost-card {
  background: $grey-1-mayday;
  height: 2px;
  border-top: 2px solid $blue-mayday;
  :deep() .task-box {
    visibility: hidden;
  }
}

.draggable-task {
  height: 100%;
  padding-bottom: 32px;
  padding-left: 1px;
  padding-right: 1px;
  overflow-y: scroll;
  overflow-x: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
