<template>
  <div class="w-full">
    <div
      :class="[
        'bg-white shadow-custom p-2 task-box',
        { 'selected-task': selected, disabled: !archivePermission },
      ]"
      @click="handleCardOnClick"
    >
      <div class="animation" v-if="isAnimated">
        <lottie-animation path="lottie/74694-confetti.json" />
      </div>
      <div :class="[isAnimated ? 'hide-if-animated' : 'not-animated']">
        <div class="d-flex flex-row justify-content-between">
          <div
            class="d-flex justify-content-start align-items-start task-header"
          >
            <div
              v-if="!selected"
              :class="[
                'align-items-center justify-content-center',
                `type-icon-${currentTaskTypeConfiguration.classTag}-wrapper`,
              ]"
            >
              <font-awesome-icon
                :icon="['fad', taskIcon]"
                :class="[`type-icon-${currentTaskTypeConfiguration.classTag}`]"
              />
            </div>
            <div
              :class="[
                'align-items-center justify-content-center',
                `checkbox-${currentTaskTypeConfiguration.classTag}-wrapper`,
              ]"
              :style="checkboxChecked"
            >
              <el-checkbox
                :value="selected"
                @change="handleCheckboxChange"
                class="mb-0 d-flex checkbox"
              ></el-checkbox>
            </div>
            <p class="task-title mb-0">
              {{ taskTitle }}
            </p>
          </div>
          <div class="expand-wrapper">
            <el-tooltip :content="$t('task-view.expand-tooltip')">
              <font-awesome-icon
                :icon="['fas', 'expand']"
                class="icon-enlarge"
                @click="$emit('open-focus-task', { taskId: task.id })"
              />
            </el-tooltip>
          </div>
        </div>

        <div class="d-flex flex-row align-items-center task-info">
          <el-tooltip :content="$t('task-view.date-tooltip')">
            <div class="task-date d-flex align-items-center">
              <font-awesome-icon
                :icon="['fal', 'calendar-week']"
                class="icon-calendar mr-1"
              />
              <span class="sm">{{ task.createdAt }}</span>
            </div>
          </el-tooltip>
          <el-tooltip
            v-if="currentTaskAttributes.linkedContent"
            :content="$t('task-view.link-tooltip')"
            ><div class="task-link">
              <div
                v-if="currentTaskAttributes.linkedContent"
                class="icon-circle rounded-circle"
                @click.stop="handleRedirect"
              >
                <font-awesome-icon :icon="['fal', 'link']" class="icon-link" />
              </div>
            </div>
          </el-tooltip>
          <el-tooltip
            v-if="knowledge"
            :content="
              $t('task-view.knowledge-tooltip', { label: knowledge.label })
            "
            effect="dark"
            ><div class="task-link">
              <div
                v-if="task.type === 'CONTENT_FEEDBACK' || isObsolete"
                class="icon-circle rounded-circle"
              >
                <font-awesome-icon :icon="['fad', 'books']" class="icon-link" />
              </div>
            </div>
          </el-tooltip>
          <el-tooltip
            v-if="!isInfo && !isObsolete"
            :content="
              authorHasUsername
                ? $t('task-view.author-tooltip', {
                    name: authorUsername,
                  })
                : $t('task-view.author-tooltip-deleted-user')
            "
          >
            <div class="d-flex flex-row align-items-center">
              <user-avatar
                :username="authorUsername"
                size="xsmall"
                class="mr-2 avatar"
              ></user-avatar></div
          ></el-tooltip>
          <el-tooltip v-else :content="$t('task-view.admin-author-tooltip')">
            <div class="d-flex flex-row align-items-center">
              <img
                src="@/assets/mayday-logo.png"
                alt="mayday-logo-rounded"
                class="logo-admin"
              /></div
          ></el-tooltip>
        </div>
        <div
          ref="innerHtmlContainer"
          class="inner-html-body d-flex flex-column p-0"
          :class="isInnerHtmlOverflowing ? 'mb-2' : ''"
        >
          <div class="body" v-html="sanitize(body)" />
          <div
            class="inner-html-overflow-cover"
            v-if="isInnerHtmlOverflowing"
          />
        </div>
        <div
          class="task-footer d-flex flex-row justify-content-between align-items-center"
        >
          <div
            v-if="currentTaskAttributes.answer"
            class="d-flex flex-row align-items-center"
          >
            <div class="icon-wrapper">
              <font-awesome-icon
                :icon="['fas', 'comment']"
                :class="
                  task.commentCount > 0
                    ? 'notification-active'
                    : 'notification-inactive'
                "
              />
            </div>
            <div
              :class="task.commentCount > 0 ? 'count-active' : 'count-inactive'"
            >
              {{ task.commentCount }}
            </div>
            <el-tooltip
              v-if="currentTaskAttributes.reaction"
              :disabled="!updatePermission"
            >
              <template slot="content">
                <div v-if="taskHasUpvotes > 0">
                  <div v-for="upvoter in task.upvotes" :key="upvoter.id">
                    {{ upvoter.username }}
                  </div>
                </div>
                <div v-else>
                  {{ $t('task-view.empty-upvote-tooltip') }}
                </div>
              </template>
              <div class="icon-wrapper" @click.stop="handleUpvote">
                <font-awesome-icon
                  :icon="[taskHasUpvotes ? 'fas' : 'fal', 'heart']"
                  :class="[
                    taskHasUpvotes
                      ? 'notification-active'
                      : 'notification-inactive',
                    'upvote',
                  ]"
                /></div
            ></el-tooltip>
            <div v-if="taskHasUpvotes" class="count-active">
              {{ task.upvoteCount }}
            </div>
            <div
              v-if="task.rating && task.rating.average"
              class="d-flex flex-row align-items-center"
            >
              <div class="icon-wrapper">
                <font-awesome-icon
                  :icon="['fas', 'star']"
                  class="notification-inactive"
                />
              </div>
              <div class="count-inactive">{{ task.rating.average }}/5</div>
            </div>
            <div
              class="px-1 text-xs underline text-blue"
              v-if="isInnerHtmlOverflowing"
            >
              {{ $t('task-view.show-more') }}
            </div>
          </div>
          <el-tooltip
            v-if="task.assignee"
            :content="
              $t('task-view.assignee-tooltip', {
                name: taskAssigneeLabel,
              })
            "
            effect="dark"
          >
            <user-avatar
              v-if="task.assignee"
              :username="taskAssigneeLabel"
              size="xsmall"
              class="avatar"
            ></user-avatar
          ></el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import UserAvatar from '../../../components/UserAvatar.vue';
// import MaydayFeedbackEditor from '@/components/Editors/MaydayFeedbackEditor';
import { mapState } from 'vuex';
import { taskTypeConfiguration, getTaskAttributes } from './taskTypeDisplay.js';
import { sanitize } from '@/plugins/dompurify';

export default {
  name: 'TaskCard',
  components: {
    UserAvatar,
    // MaydayFeedbackEditor,
  },
  data() {
    return {
      isAnimated: false,
      sanitize: sanitize,
      isInnerHtmlOverflowing: false,
    };
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    animatedTaskId: {
      type: String,
      default: '',
    },
    knowledge: {
      type: Object,
      required: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    updatePermission: {
      type: Boolean,
      default: false,
    },
    archivePermission: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.checkOverflow();
    this.$watch(() => this.task.body, this.checkOverflow);
  },
  methods: {
    handleCardOnClick(e) {
      if (e.target.className.includes('el-checkbox')) return;
      this.$emit('open-focus-task', { taskId: this.task.id });
    },
    async handleRedirect() {
      const { entityId, entityType, knowledgeId, lang } = this.task;

      if (entityType === 'Slide' || entityType === 'PublishedModule') {
        this.goToTranslatedAcademyEntity({
          slideId: entityId,
          publishedModuleId: knowledgeId,
          entityType,
          lang,
        });
      } else {
        const path = await this.createRedirectUrl({
          entityId,
          entityType,
          target: 'knowledge',
        });
        this.$router.push({
          path: path,
          query: {
            from: 'hub',
          },
        });
      }
    },
    handleCheckboxChange(value) {
      this.$emit('update-selected-tasks', {
        taskId: this.task.id,
        action: value ? 'add' : 'remove',
      });
    },
    ...mapActions(['createRedirectUrl']),
    ...mapActions('academyModule', ['goToTranslatedAcademyEntity']),
    handleUpvote() {
      if (!this.updatePermission) return;
      if (this.upvotersId.includes(this.userId))
        return this.$emit('remove-upvote', {
          taskId: this.task.id,
          statusId: this.task.companyTaskStatusId,
        });
      this.$emit('add-upvote', {
        taskId: this.task.id,
        statusId: this.task.companyTaskStatusId,
      });
    },
    checkOverflow() {
      const innerHtmlContainer = this.$refs.innerHtmlContainer;
      if (!innerHtmlContainer) return;
      const { clientWidth, clientHeight, scrollWidth, scrollHeight } =
        innerHtmlContainer;
      this.isInnerHtmlOverflowing =
        scrollWidth > clientWidth || scrollHeight > clientHeight;
    },
  },
  computed: {
    authorUsername() {
      const authorHasUsername = this.task.author && this.task.author.username;
      if (!authorHasUsername) return this.$t('task-view.deleted-user');

      if (this.task.author.trad) return this.$t(this.task.author.username);

      return this.task.author.username;
    },
    authorHasUsername() {
      return this.task.author && this.task.author.username;
    },
    taskAssigneeLabel() {
      return this.task.assignee.username
        ? this.task.assignee.username
        : this.task.assignee.name;
    },

    checkboxChecked() {
      if (this.selected) return { display: 'flex' };
      return {};
    },
    upvotersId() {
      return this.task.upvotes.map((upvoter) => upvoter.id);
    },
    taskHasUpvotes() {
      return this.task.upvoteCount > 0;
    },
    isInfo() {
      return this.task.type === 'INFORMATION';
    },
    isObsolete() {
      return this.task.type === 'CONTENT_OBSOLETE';
    },
    body() {
      return this.isInfo
        ? this.task.body.slice(0, 138)
        : this.isObsolete
        ? this.$t(this.task.body.path, {
            title: this.task.body.title,
            dueDate: this.task.body.dueDate,
          })
        : this.task.body;
    },
    currentTaskTypeConfiguration() {
      return taskTypeConfiguration[this.task.type];
    },
    currentTaskAttributes() {
      return getTaskAttributes(this.task.type);
    },
    isCustomFeedback() {
      return this.task.type === 'CUSTOM_FEEDBACK';
    },
    taskTitle() {
      if (this.isCustomFeedback) return this.task.customName;
      return this.task.title.path
        ? this.$t(this.task.title.path, {
            name: this.task.title.name,
            title: this.task.title.title,
          })
        : this.task.title;
    },
    taskIcon() {
      return this.isCustomFeedback
        ? this.task.customIcon
        : this.currentTaskTypeConfiguration.icon;
    },
    ...mapState(['userId']),
  },
  watch: {
    animatedTaskId() {
      if (this.animatedTaskId === this.task.id) {
        this.isAnimated = true;
        setTimeout(() => {
          return (this.isAnimated = false);
        }, 1500);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$blue-grey: #525f7f;

.type-icon-feedback-wrapper {
  width: 24px;
  height: 24px;
  padding: 4px;
  background-color: rgba($blue-mayday, 0.1);
  margin-right: 4px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
}

.type-icon-info-wrapper {
  height: 24px;
  padding: 4px;
  background-color: rgba($yellow-mayday, 0.1);
  margin-right: 4px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
}

.type-icon-obsolete-wrapper {
  height: 24px;
  padding: 4px;
  background-color: rgba($red-mayday, 0.1);
  margin-right: 4px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
}

.checkbox-feedback-wrapper {
  width: 24px !important;
  height: 24px;
  padding: 4px;
  margin-right: 4px;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 4px;
  display: none;
}

.checkbox-info-wrapper {
  width: 24px !important;
  height: 24px;
  padding: 4px;
  margin-right: 4px;
  background-color: rgba($yellow-mayday, 0.1);
  border-radius: 4px;
  display: none;
}

.checkbox-obsolete-wrapper {
  width: 24px !important;
  height: 24px;
  padding: 4px;
  margin-right: 4px;
  background-color: rgba($red-mayday, 0.1);
  border-radius: 4px;
  display: none;
}

.checkbox {
  :deep() .el-checkbox__input {
    margin: auto;
    width: 16px;
    height: 16px;
  }
  :deep() .is-checked {
    visibility: visible;
  }
  :deep() .el-checkbox__inner {
    height: 16px;
    width: 16px;
    padding: 2px;
    background-clip: content-box;
  }
}

:deep() .el-checkbox__input.is-checked .el-checkbox__inner::after {
  display: none;
}

.type-icon-feedback {
  color: $blue-mayday;
  width: 16px;
  height: 16px;
}

.type-icon-info {
  color: $yellow-mayday;
  width: 16px;
  height: 16px;
}

.type-icon-obsolete {
  color: $red-mayday;
  width: 16px;
  height: 16px;
}

.task-title {
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: left;
  line-height: 24px;
  padding-right: 5px;
}

.icon-enlarge {
  width: 12px;
  height: 12px;
  display: none;
  color: $grey-6-mayday;
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}

.icon-calendar {
  width: 12px;
  height: 12px;
  color: #525f7f;
}

.task-box:hover .icon-enlarge {
  display: block;
}
.task-box:hover .expand-wrapper {
  border: 0.5px solid $grey-2-mayday;
  border-radius: 2px;
  position: relative;
  right: 2px;
  z-index: 1;
  width: 24px;
  height: 24px;
  display: flex;
  visibility: visible;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 1px 1px 2px $grey-4-mayday;
  padding: 4px;
  &:hover {
    background-color: $grey-1-mayday;
  }
}

.task-box:hover {
  &:not(.disabled) {
    .checkbox-feedback-wrapper,
    .checkbox-info-wrapper,
    .checkbox-obsolete-wrapper {
      display: flex;
    }

    .type-icon-feedback-wrapper,
    .type-icon-info-wrapper,
    .type-icon-obsolete-wrapper {
      display: none;
    }
  }
}

.task-box {
  cursor: grab;
  width: 100%;
  border-radius: 2px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.selected-task {
  outline: 1px solid $blue-mayday;
}

.task-info {
  margin-top: 10px;
}

.task-date {
  background-color: $grey-1-mayday;
  color: $grey-6-mayday;
  border-radius: 10px;
  padding: 1px 6px;
  font-weight: 700;
  font-size: 12px;
  margin-right: 15px;
  color: $blue-grey;
}

.icon-circle {
  width: 20px;
  height: 20px;
  background-color: $grey-1-mayday;
  color: $blue-grey;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: rgba($blue-mayday, 0.1);
    color: $blue-mayday;
  }
}

.avatar {
  width: 20px !important;
  height: 20px !important;
}

.logo-admin {
  width: 20px;
  height: 20px;
  background-color: #f0f3f7;
  box-shadow: 0px 4px 10px $shadow-4-mayday;
  border-radius: 50%;
  border: 1px solid white;
  padding: 2px;
}

.task-link {
  margin-right: 15px;
}

.icon-link {
  width: 12x;
  height: 12px;
  color: $blue-grey;
}

.task-creator-icon {
  margin-right: 6px;
  background-color: var(--background-color);
  width: 16px;
  height: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  font-size: 10px;
}

.body {
  font-family: 'Lato';
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02rem;
  text-align: left;
  margin: 8px 4px;

  &:hover {
    cursor: pointer;
  }

  :deep() p {
    font-family: 'Lato';
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02rem;
    text-align: left;
    margin: 2px 4px;
    word-break: break-all;
  }
}

.inner-html-body {
  overflow: hidden;
  max-height: 112px;
}

.inner-html-overflow-cover {
  position: sticky;
  bottom: 0;
  width: 100%;
  min-height: 30px;
  background-image: linear-gradient(transparent, white);
}

.icon-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-active {
  color: $blue-mayday;
  width: 12px;
  height: 12px;
}

.notification-inactive {
  color: $grey-4-mayday;
  width: 12px;
  height: 12px;
}
.notification-inactive:hover {
  width: 13px;
  height: 13px;
}

.count-active {
  //styleName: Mini/Bold;
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-right: 2px;
}

.count-inactive {
  //styleName: Mini/Bold;
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 0.02em;
  text-align: left;
  color: $grey-4-mayday;
  margin-right: 2px;
}

.task-creator-name {
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  color: $grey-6-mayday;
}

.shadow-custom {
  box-shadow: 0px 0px 0px 1px rgba(15, 15, 15, 0.05),
    0px 2px 4px rgba(15, 15, 15, 0.05);
}

.hide-if-animated {
  opacity: 0.5;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  z-index: 2;
}

.not-animated {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
}

.animation {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  width: 260px;
  height: 105px;
  z-index: 1;
}

.upvote {
  cursor: pointer;
}
.expand-wrapper {
  width: 24px;
  visibility: hidden;
}

:deep() .mayday-editor {
  max-width: 100%;
}

:deep() .mayday-editor__content__ProseMirror p {
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: left;
}
</style>
