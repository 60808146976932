<template>
  <div>
    <div class="description">
      {{ $t('settings-layout.menu-data.ai-settings.description') }}
    </div>
    <div class="footer-wrapper">
      <div class="badge-wrapper">
        <font-awesome-icon
          class="badge-icon"
          :class="[
            hasAskActivated ? 'badge-icon__enable' : 'badge-icon__disable',
          ]"
          :icon="['fal', hasAskActivated ? 'check-circle' : 'times-circle']"
        />
        {{
          $t(
            `settings-layout.menu-data.ai-settings.${
              hasAskActivated ? 'enable' : 'disable'
            }`,
          )
        }}
      </div>
      <a href="/settings-v2/ai" v-if="hasAskActivated">
        <el-button type="primary" size="small">{{
          $t('generic.configure')
        }}</el-button>
      </a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'configure-ai',
  computed: {
    hasAskActivated() {
      return this.hasCompanyPreferenceWithValue('BRAIN_ENABLE_ASK');
    },
    ...mapGetters(['hasCompanyPreferenceWithValue']),
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.description {
  font-size: 14px;
  color: $grey-6-mayday;
}
.footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.badge-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.badge-icon {
  font-size: 14px;
  height: 16px;
  width: 16px;
  &__enable {
    color: $purple-5-mayday;
  }
  &__disable {
    color: $red-5-mayday;
  }
}
.el-button {
  background-color: $purple-5-mayday;
  border: none;
  height: 22px;
  display: flex;
  align-items: center;
}
</style>
